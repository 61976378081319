<template>
  <div class="districon">
    <div class="btn">
      <el-button type="primary" @click="add">新增</el-button>
    </div>
    <div class="tablediv">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="forbidLogisticsId" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="describe" label="不可发货原因" align="center">
        </el-table-column>
        <el-table-column prop="address" align="center" label="是否启用">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.start"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              @change="
                (val) => {
                  swithchange(val, scope.row);
                }
              "
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="新增时间">
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="delt(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="delet(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="新增不可发货原因"
      :visible.sync="dialogVisible"
      width="25%"
      :before-close="handleClose"
    >
      <el-form
        :rules="rules"
        :model="ruleForm"
        ref="ruleForm"
        label-width="120px"
        class="frame"
      >
        <el-form-item label="不可发货原因" prop="name">
          <el-input maxlength="100" v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="quer('ruleForm')">确认</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div class="block">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  GetForbidLogistics,
  UpForbidLogistics,
  AddForbidLogistics,
} from "../../api/nologistics";
export default {
  data() {
    return {
      start: false,
      total: 0,
      type: 1,
      forbidLogisticsId: "",
      tableData: [],
      currentPage: 1,
      pagesize: 10,
      dialogVisible: false,
      ruleForm: {
        name: "",
        remark: "",
      },
      value: "",
      rules: {
        name: [
          { required: true, message: "请输入不可发货原因", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    swithchange(val, row) {
      console.log(val, row);
      delete row.createTime;
      console.log(row);
      UpForbidLogistics(row).then((res) => {
        if (res.code == 10000) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getlist();
        }
      });
    },

    quer(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.type == 1) {
            let data = {
              describe: this.ruleForm.name,
            };
            AddForbidLogistics(data).then((res) => {
              if (res.code == 10000) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.ruleForm.name = "";
                this.dialogVisible = false;
                this.getlist();
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
                this.ruleForm.name = "";
                this.dialogVisible = false;
                this.getlist();
              }
            });
          } else {
            let data = {
              describe: this.ruleForm.name,
              forbidLogisticsId: this.forbidLogisticsId,
              isDelete: false,
              start: this.start,
            };
            UpForbidLogistics(data).then((res) => {
              if (res.code == 10000) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.ruleForm.name = "";
                this.dialogVisible = false;
                this.getlist();
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
                this.ruleForm.name = "";
                this.dialogVisible = false;
                this.getlist();
              }
            });
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },

    getlist() {
      let data = {
        pageIndex: this.currentPage,
        pageSiz: this.pagesize,
      };
      GetForbidLogistics(data).then((res) => {
        console.log(res);
        if (res.code == 10000) {
          this.total = res.total;
          this.tableData = res.data;
          for (let i = 0; i < this, this.tableData.length; i++) {
            this.tableData[i].createTime = this.tableData[i].createTime.replace(
              "T",
              "  "
            );
          }
        }
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagesize = val;
      this.getlist();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getlist();
    },
    handleClose() {
      this.ruleForm.name = "";
      this.dialogVisible = false;
    },
    add() {
      this.type = 1;
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });
      this.dialogVisible = true;
    },
    delt(row) {
      console.log(row);

      this.dialogVisible = true;
      this.type = 2;
      this.start = row.start;
      this.ruleForm.name = row.describe;
      this.forbidLogisticsId = row.forbidLogisticsId;
    },
    handleChange() {},
    delet(row) {
      if (row.start == true) {
        this.$message.error("该延时不可发货原因正在使用，无法删除");
      } else {
        this.$confirm("删除该延时不可发货原因，是否继续？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            row.isDelete = true;
            delete row.createTime;
            UpForbidLogistics(row).then((res) => {
              if (res.code == 10000) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.getlist();
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
                this.getlist();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
  },
};
</script>

<style scoped>
.districon {
  width: 100%;
  height: 100%;
  background: white;
  margin-top: 10px;
}
.btn {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-top: 5px;
  float: right;
}
.tablediv {
  margin-top: 15px;
}
.block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.footer {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
</style>